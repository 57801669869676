<script setup lang="ts">
import type { LineItem } from '@shopware-pwa/types';

defineProps<{ buttonClasses?: string }>();

const { appliedPromotionCodes, addPromotionCode, removeItem } = useCart();
const { codeErrorsNotification } = useCartNotification();
const addPromotionCodeHandler = async (code: string) => {
  await addPromotionCode(code);
  codeErrorsNotification();
  promoCode.value = '';
};

const removeItemHandler = (appliedPromotionCode: LineItem) => {
  removeItem(appliedPromotionCode);
  codeErrorsNotification();
};

const showPromotionCodes = computed(
  () => appliedPromotionCodes.value.length > 0,
);

const promoCode = ref('');
const showPromoCodeInput = ref(false);
</script>

<template>
  <div class="border-dark flex flex-col border-y">
    <button
      class="text-primary flex items-center justify-between bg-white py-4 text-sm font-bold uppercase"
      @click="showPromoCodeInput = !showPromoCodeInput"
    >
      {{ $t('cart.promocode.title') }}
      <i
        v-if="showPromoCodeInput"
        aria-hidden
        class="icon i-custom-svg:minus text-primary block text-xl"
      />
      <i
        v-else
        aria-hidden
        class="icon i-custom-svg:plus text-primary block text-xl"
      />
    </button>
    <div v-if="showPromoCodeInput" class="relative grid grid-cols-5 gap-2 pb-6">
      <SharedSpecialInput
        v-model="promoCode"
        additional-classes="h-full"
        class="col-span-3"
        :label="$t('cart.promocode.label')"
        name="promoCode"
      />
      <button
        class="btn btn-solid col-span-2 text-center"
        @click="addPromotionCodeHandler(promoCode)"
      >
        {{ $t('cart.promocode.apply') }}
      </button>
    </div>
  </div>
  <div v-if="showPromotionCodes">
    <div>{{ $t('checkout.promoCode.label') }}:</div>
    <ul role="list" class="pl-0 text-sm">
      <li
        v-for="appliedPromotionCode in appliedPromotionCodes"
        :key="appliedPromotionCode.id"
        class="flex justify-between border-b py-4 text-gray-600"
      >
        <span>{{ appliedPromotionCode.label }}</span>
        <button
          class="text-brand-dark appearance-none border-none bg-transparent"
          type="button"
          @click="() => removeItemHandler(appliedPromotionCode)"
        >
          {{ $t('checkout.promoCode.remove') }}
        </button>
      </li>
    </ul>
  </div>
</template>
